import React from "react";

const jeffreyInteractive = () => {
  return (
    <div id="jeffrey-interactive">
      <svg
        id="jeffrey-interactive__svg"
        viewBox="0 0 276 492"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="face"
          d="M18.412 159.151V178.846C18.412 179.229 18.412 179.623 18.412 180.006C18.4786 186.54 19.0861 193.058 20.2283 199.492C25.2553 229.032 41.1202 255.633 64.7206 274.096C88.321 292.558 117.959 301.553 147.84 299.322C177.721 297.091 205.695 283.793 226.291 262.031C246.888 240.268 258.626 211.605 259.21 181.647C259.21 180.706 259.21 179.754 259.21 178.802V159.108C254.597 159.108 250.172 157.275 246.909 154.012C243.646 150.75 241.814 146.325 241.814 141.711V98.2625H59.4204C49.4885 98.2799 39.724 95.7039 31.0931 90.7896V146.503C31.0931 148.167 30.7649 149.814 30.1272 151.351C29.4895 152.888 28.5549 154.284 27.3769 155.458C26.1989 156.633 24.8007 157.564 23.2624 158.198C21.724 158.832 20.0758 159.156 18.412 159.151V159.151Z"
          strokeLinejoin="round"
        />
        <path
          className="lefthand"
          d="M20.9835 343.021V376.163C20.9851 381.914 22.1226 387.608 24.3307 392.918C26.5388 398.228 29.774 403.049 33.8506 407.105L34.146 407.39C35.4802 396.521 40.4189 386.412 48.1729 378.68C49.2882 377.559 50.4605 376.496 51.6851 375.496V343.021H20.9835Z"
          strokeLinejoin="round"
        />
        <path
          className="leftleg"
          d="M33.7306 413.276V489.024H131.755V446.768H110.649C95.0204 446.736 80.0414 440.514 68.9905 429.463C57.9397 418.412 51.7173 403.433 51.6855 387.805V375.496C50.4609 376.496 49.2886 377.559 48.1733 378.68C40.4215 386.41 35.483 396.514 34.1464 407.379C33.8855 409.334 33.7466 411.304 33.7306 413.276V413.276Z"
          strokeLinejoin="round"
        />
        <path
          className="leftarm"
          d="M51.0506 261.224C42.3195 264.138 34.7222 269.717 29.3284 277.175C23.9347 284.634 21.0161 293.597 20.9836 302.801V343H51.6852V288.369C51.6889 280.77 53.1756 273.243 56.0618 266.213"
          strokeLinejoin="round"
        />
        <path
          className="rightleg"
          d="M51.685 368.209V387.827C51.7226 403.451 57.9475 418.425 68.9978 429.471C80.048 440.517 95.0238 446.736 110.648 446.768H144.982V489.078H242.952V424.634C236.786 427.946 229.911 429.72 222.913 429.806C215.914 429.892 208.998 428.286 202.753 425.126C195.501 421.47 189.403 415.879 185.133 408.972C180.864 402.064 178.589 394.109 178.562 385.989V368.209H51.6412H51.685Z"
          strokeLinejoin="round"
        />
        <path
          className="shirt"
          d="M51.6852 288.369V368.242H178.605V312.67C178.601 304.715 180.781 296.911 184.908 290.109C163.416 299.01 139.806 301.492 116.933 297.255C94.0608 293.018 72.9061 282.243 56.0289 266.235C53.1568 273.261 51.6813 280.779 51.6852 288.369V288.369Z"
          strokeLinejoin="round"
        />
        <path
          className="righteye"
          d="M154.348 159.151C154.345 162.888 155.306 166.562 157.138 169.819C158.971 173.075 161.612 175.804 164.807 177.741C168.003 179.678 171.643 180.758 175.378 180.877C179.113 180.995 182.815 180.148 186.126 178.417C189.438 176.687 192.247 174.131 194.282 170.997C196.317 167.863 197.509 164.257 197.743 160.528C197.977 156.799 197.245 153.072 195.617 149.709C193.99 146.345 191.522 143.459 188.453 141.328H163.681C160.799 143.321 158.443 145.985 156.818 149.089C155.192 152.194 154.344 155.647 154.348 159.151V159.151Z"
          strokeLinejoin="round"
        />
        <path
          className="rightpupil"
          d="M176.068 168.2C171.07 168.2 167.019 164.149 167.019 159.152C167.019 154.154 171.07 150.103 176.068 150.103C181.065 150.103 185.116 154.154 185.116 159.152C185.116 164.149 181.065 168.2 176.068 168.2Z"
          strokeLinejoin="round"
        />
        <path
          className="rightbrow"
          d="M149.895 132.214C149.907 134.629 150.872 136.943 152.581 138.65C154.291 140.357 156.605 141.319 159.02 141.328H192.939C195.303 141.246 197.543 140.249 199.186 138.548C200.83 136.847 201.748 134.574 201.748 132.208C201.748 129.843 200.83 127.57 199.186 125.869C197.543 124.167 195.303 123.171 192.939 123.089H159.02C156.603 123.097 154.287 124.061 152.578 125.771C150.868 127.48 149.904 129.796 149.895 132.214V132.214Z"
          strokeLinejoin="round"
        />
        <path
          className="lefteye"
          d="M46.5867 158.845C46.5353 162.577 47.4472 166.259 49.2342 169.536C51.0212 172.813 53.623 175.574 56.7883 177.552C59.9536 179.53 63.5754 180.659 67.3041 180.829C71.0328 180.999 74.7423 180.204 78.0744 178.522C81.4065 176.84 84.2486 174.328 86.3262 171.227C88.4039 168.126 89.6469 164.542 89.9352 160.82C90.2236 157.099 89.5475 153.366 87.9723 149.982C86.3971 146.598 83.9759 143.678 80.9427 141.503L75.472 141.426L56.2151 141.153C53.2988 143.103 50.8992 145.731 49.2222 148.813C47.5451 151.895 46.6408 155.337 46.5867 158.845V158.845Z"
          strokeLinejoin="round"
        />
        <path
          className="leftpupil"
          d="M68.3052 168.2C63.3079 168.2 59.2567 164.149 59.2567 159.152C59.2567 154.154 63.3079 150.103 68.3052 150.103C73.3026 150.103 77.3538 154.154 77.3538 159.152C77.3538 164.149 73.3026 168.2 68.3052 168.2Z"
          strokeLinejoin="round"
        />
        <path
          className="leftbrow"
          d="M42.7241 131.842C42.6949 134.259 43.6251 136.59 45.3109 138.323C46.9966 140.056 49.3006 141.05 51.718 141.087L56.1602 141.153L75.417 141.427L80.8877 141.503L85.5378 141.569H85.6582C88.024 141.506 90.2727 140.526 91.9298 138.836C93.5869 137.147 94.5226 134.879 94.5397 132.513C94.5567 130.146 93.6537 127.866 92.0211 126.152C90.3885 124.439 88.1541 123.427 85.7895 123.33L51.8711 122.848H51.7508C49.3738 122.885 47.1039 123.843 45.4198 125.521C43.7358 127.199 42.7692 129.465 42.7241 131.842V131.842Z"
          strokeLinejoin="round"
        />
        <path
          className="leftnose"
          d="M111.851 219.909C107.192 219.909 103.415 216.132 103.415 211.473C103.415 206.814 107.192 203.037 111.851 203.037C116.51 203.037 120.287 206.814 120.287 211.473C120.287 216.132 116.51 219.909 111.851 219.909Z"
          strokeLinejoin="round"
        />
        <path
          className="rightnose"
          d="M132.629 219.91C127.97 219.91 124.194 216.133 124.194 211.474C124.194 206.815 127.97 203.038 132.629 203.038C137.288 203.038 141.065 206.815 141.065 211.474C141.065 216.133 137.288 219.91 132.629 219.91Z"
          strokeLinejoin="round"
        />
        <path
          className="righthand"
          d="M178.562 352.869V386.01C178.562 397.631 183.178 408.775 191.395 416.992C199.612 425.209 210.756 429.825 222.377 429.825C233.997 429.825 245.141 425.209 253.358 416.992C261.575 408.775 266.191 397.631 266.191 386.01V352.869H178.562Z"
          strokeLinejoin="round"
        />
        <path
          className="rightarm"
          d="M178.562 312.67V352.869H266.191V312.67C266.163 301.067 261.544 289.946 253.344 281.736C245.144 273.526 234.029 268.893 222.426 268.85C221.157 268.85 219.898 268.905 218.651 269.014C208.654 277.872 197.25 285.002 184.908 290.109C180.766 296.906 178.57 304.711 178.562 312.67V312.67Z"
          strokeLinejoin="round"
        />
        <path
          className="mouth"
          d="M124.183 274.43C141.547 274.43 157.007 266.891 162.708 255.666C163.031 255.019 163.085 254.271 162.858 253.585C162.631 252.898 162.142 252.33 161.497 252.003C160.852 251.676 160.105 251.618 159.417 251.841C158.729 252.064 158.158 252.55 157.828 253.193C153.057 262.624 139.534 268.959 124.183 268.959C108.832 268.959 94.9147 262.34 90.374 252.854C90.0606 252.199 89.5002 251.696 88.8158 251.455C88.1315 251.214 87.3795 251.255 86.7251 251.568C86.0707 251.881 85.5677 252.442 85.3266 253.126C85.0855 253.811 85.1261 254.563 85.4395 255.217C90.943 266.705 106.513 274.43 124.183 274.43Z"
          fill="#102A44"
        />
        <path
          d="M258.893 91.6101L259.254 159.151V99.1159C259.254 96.5921 259.134 94.0902 258.893 91.6101Z"
          strokeLinejoin="round"
        />

        <path
          className="rightear"
          d="M257.406 199.952C261.804 199.86 265.992 198.052 269.075 194.914C272.159 191.776 273.892 187.556 273.905 183.157V138.494C273.896 134.411 272.406 130.47 269.712 127.401C267.018 124.333 263.302 122.346 259.255 121.808V178.802C259.255 179.754 259.255 180.706 259.255 181.647C259.109 187.787 258.491 193.907 257.406 199.952V199.952Z"
          strokeLinejoin="round"
        />
        <path
          className="rightearhole"
          d="M257.3 179.732C259.277 179.252 261.036 178.123 262.296 176.526C263.556 174.929 264.244 172.955 264.251 170.921V146.841C264.235 144.822 263.547 142.866 262.297 141.281C261.046 139.695 259.304 138.571 257.344 138.085V176.93C257.344 177.86 257.333 178.79 257.3 179.732Z"
        />
        <path
          className="leftear"
          d="M7.2412 138.494V183.157C7.23872 186.956 8.52026 190.645 10.8778 193.624C13.2354 196.603 16.5306 198.698 20.2286 199.569C19.081 193.102 18.4735 186.551 18.4124 179.984C18.4124 179.601 18.4124 179.207 18.4124 178.824V122.662C15.1492 123.833 12.3262 125.982 10.3273 128.814C8.32847 131.647 7.25091 135.027 7.2412 138.494V138.494Z"
          strokeLinejoin="round"
        />
        <path
          className="leftearhole"
          d="M12.992 146.841V170.921C12.9945 172.293 13.3079 173.648 13.9089 174.882C14.5098 176.116 15.3826 177.198 16.4617 178.046C16.4617 177.663 16.4617 177.269 16.4617 176.886V139.738C15.3825 140.581 14.5093 141.659 13.9082 142.89C13.3071 144.12 12.9938 145.472 12.992 146.841V146.841Z"
        />
        <path
          className="hair"
          d="M2.00016 20.6113V40.842C1.9716 55.8849 7.86762 70.3341 18.4123 81.0626V159.151C21.7668 159.151 24.9839 157.819 27.3559 155.447C29.728 153.075 31.0605 149.858 31.0605 146.503V90.7895C39.6915 95.7039 49.4559 98.2798 59.3878 98.2625H241.858V141.755C241.858 146.368 243.69 150.793 246.953 154.056C250.216 157.318 254.64 159.151 259.254 159.151L258.893 91.6101L258.718 59.4424C258.721 51.9 257.238 44.4309 254.354 37.4618C251.469 30.4927 247.24 24.1601 241.908 18.8258C236.576 13.4915 230.245 9.25999 223.277 6.37297C216.309 3.48595 208.84 2 201.298 2H20.6005C18.157 2 15.7374 2.48148 13.48 3.41692C11.2225 4.35236 9.17157 5.72343 7.44422 7.45179C5.71687 9.18016 4.34699 11.2319 3.41288 13.4899C2.47877 15.7479 1.99873 18.1678 2.00016 20.6113V20.6113Z"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default jeffreyInteractive;
