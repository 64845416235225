import React from "react";

const moonsvg = () => {
  return (
    <div className="modeicon">
      <div className="moon"></div>
      <div className="sun"></div>
    </div>
  );
};

export default moonsvg;
