import React, { Component } from "react";
import ContactForm from "../../components/contact-form/contact-form";
class Contact extends Component {
  state = {};
  render() {
    return <ContactForm />;
  }
}

export default Contact;
